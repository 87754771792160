






























































































import { Component, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { TranslateResult } from 'vue-i18n';
import { AxiosResponse } from 'axios';
import { FETCH_CONTACT, GET_CONTACT_BY_ID } from '@/modules/contact/store';
import ConservatorshipSelect from '@/modules/conservatorship/components/ConservatorshipSelect.vue';
import AddressSelect from '@/modules/contact/components/AddressSelect.vue';
import ContactPersonSelect from '@/modules/contact/components/ContactPersonSelect.vue';
import ReceiverTypeSelect from '@/modules/correspondence/components/ReceiverTypeSelect.vue';
import { LetterTemplate, ReceiverType } from '../types';
import { ApiResponse } from '@/components/types';
import { Contact } from '@/modules/contact/types';
import AppTable from '@/components/AppTable.vue';
import { routeToLocation } from '@/router';

const Contact = namespace('contact');

@Component({
  components: { AppTable, ReceiverTypeSelect, ContactPersonSelect, ContactSelect: AddressSelect, ConservatorshipSelect }
})
export default class LetterBuilder extends Vue {
  showConfig: boolean = false;
  templates: LetterTemplate[] = [];
  loading: boolean = false;
  search: string = '';
  referrer: string = '';

  @Contact.Action(FETCH_CONTACT) fetchContact!: (id: string) => Promise<ApiResponse>;
  @Contact.Getter(GET_CONTACT_BY_ID) getContact!: (id: string) => Contact;

  get conservatorship(): string | undefined {
    return this.$route.query['conservatorship'] as string;
  }

  set conservatorship(conservatorship: string | undefined) {
    if (!conservatorship) {
      const { conservatorship, ...query } = this.$route.query;
      this.$router.push(routeToLocation({ ...this.$route, query: { ...query } })).catch(() => {});
      return;
    }

    this.$router.push(routeToLocation({ ...this.$route, query: { ...this.$route.query, conservatorship } })).catch(() => {});
  }

  get contactId(): string | undefined {
    return this.$route.query['contact'] as string;
  }

  set contactId(contact: string | undefined) {
    if (!contact) {
      const { contact, ...query } = this.$route.query;
      this.$router.push(routeToLocation({ ...this.$route, query: { ...query } })).catch(() => {});
      return;
    }

    this.$router.push(routeToLocation({ ...this.$route, query: { ...this.$route.query, contact } })).catch(() => {});
  }

  get contactPerson(): string | undefined {
    return this.$route.query['contactPerson'] as string;
  }

  set contactPerson(contactPerson: string | undefined) {
    if (!contactPerson) {
      const { contactPerson, ...query } = this.$route.query;
      this.$router.push(routeToLocation({ ...this.$route, query: { ...query } })).catch(() => {});
      return;
    }

    this.$router.push(routeToLocation({ ...this.$route, query: { ...this.$route.query, contactPerson } })).catch(() => {});
  }

  get financeAccount() {
    return this.$route.query['financeAccount'] as string;
  }

  get receiverTypes(): ReceiverType[] {
    return this.$route.query['receiverTypes'] as ReceiverType[];
  }

  set receiverTypes(receiverTypes: ReceiverType[]) {
    if (!receiverTypes) {
      const { receiverTypes, ...query } = this.$route.query;
      this.$router.push(routeToLocation({ ...this.$route, query: { ...query } })).catch(() => {});
    }

    this.$router.push(routeToLocation({ ...this.$route, query: { ...this.$route.query, receiverTypes } })).catch(() => {});
  }

  get receiver() {
    return this.$route.query['receiver'] as string;
  }

  get contact(): Contact | undefined {
    if (!this.contactId) return;

    return this.getContact(this.contactId);
  }

  get contactTypes(): Array<{ value: ReceiverType, text: TranslateResult }> {
    if (!this.contact) {
      return this.receiverTypes.map((type) => ({
        value: type, text: this.$i18n.t(`contact.type.${type}`)
      }));
    }

    return this.contact.types.map((type) => ({
      value: type, text: this.$i18n.t(`contact.type.${type}`)
    }));
  }

  get contactPersons() {
    if (!this.contact) return [];

    return this.contact.contactPersons;
  }

  get headers() {
    return [
      { align: 'left', text: this.$i18n.t('common.name'), value: 'name' },
      { align: 'left', text: this.$i18n.t('common.visibility'), value: 'visibility' },
      { align: 'left', text: this.$i18n.t('label.placeholder.conservatorship'), value: null, sortable: false },
      { align: 'left', text: this.$i18n.t('label.placeholder.bankAccount'), value: null, sortable: false },
      { align: 'left', text: this.$i18n.t('common.creator'), value: 'creator' },
      { align: 'left', text: this.$i18n.t('common.actions'), value: null, sortable: false }
    ];
  }

  openCreate(template: LetterTemplate) {
    const receiverType = template.receiverType !== 'all' ? template.receiverType : this.receiverTypes[0];

    this.$router.push({
      name: 'correspondence-email-create',
      params: { template: template.id, receiverType },
      query: {
        contact: this.receiver || this.contactId || '',
        conservatorship: this.conservatorship || '',
        contactPerson: this.contactPerson || '',
        financeAccount: this.financeAccount || '',
        referrer: this.referrer
      }
    }).catch(() => {});
  }

  @Watch('conservatorship', { immediate: true })
  async onConservatorshipChanged(conservatorship: string) {
    const receiverTypes = Array.isArray(this.receiverTypes) ? this.receiverTypes : [this.receiverTypes];
    const requiresConservatorship = !!conservatorship;
    const requiresBankAccount = !!this.financeAccount;
    const contact = this.contactId;

    this.loading = true;

    try {
      const response: AxiosResponse<LetterTemplate[]> = await this.$http.get(
        `api/email-template/list-by-values`,
        { params: { receiverTypes, contact, requiresConservatorship, requiresBankAccount } }
      );

      this.templates = response.data;
    } catch (error) {
      console.warn(error);
    }

    this.loading = false;
  }

  @Watch('contactId')
  async onContactChanged(contact: string) {
    await this.fetchContact(contact);

    if (this.contact) {
      this.receiverTypes = this.contact.types;
    }

    const receiverTypes = Array.isArray(this.receiverTypes) ? this.receiverTypes : [this.receiverTypes];
    const requiresConservatorship = !!this.conservatorship;
    const requiresBankAccount = !!this.financeAccount;

    this.loading = true;

    try {
      const response: AxiosResponse<LetterTemplate[]> = await this.$http.get(
        `api/email-template/list-by-values`,
        { params: { receiverTypes, contact, requiresConservatorship, requiresBankAccount } }
      );

      this.templates = response.data;
    } catch (error) {
      console.warn(error);
    }

    this.loading = false;
  }

  async created() {
    this.referrer = this.$route.query['referrer'] as string;

    if (!this.contactId) return;

    await this.fetchContact(this.contactId);

    if (this.contact) {
      this.receiverTypes = this.contact.types;
    }
  }
}
